:root {
    --primary-color: #f15b2a;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@layer base {
    * {
        @apply font-[Lato];
    }

    h1 {
        @apply text-3xl font-bold;
    }
}
